import React from "react";
import Spinner from "react-spinkit";
import "./Rsvp.css";

class Rsvp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  hideSpinner = () => {
    this.setState({
      loading: false
    });
  };
  render() {
    return (
      <div className="container rsvp-wrapper">
        {this.state.loading ? (
          <Spinner
            className="loading text-center"
            name="three-bounce"
            color="white"
            fadeIn="none"
          />
        ) : null}
        <iframe
          id="wrapper-screen"
          key="wrapper-screen"
          title="wrapper-screen"
          src="https://www.foodbooking.com/ordering/restaurant/menu?restaurant_uid=8258b6c6-0352-41db-815b-00366d76cf51&glfa_cid=1729322915.1602644450&glfa_t=1606498924234"
          width="100%"
          height="100%"
          onLoad={this.hideSpinner}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        />
      </div>
    );
  }
}

export default Rsvp;
